import React from 'react';
import Helmet from 'react-helmet';
import Page from '../components/page';
import Section from '../components/section';
import { LayoutRow, MainColumn } from '../utils/layout-row';

const ContactSubmitPage = ({ location }) => {
  return (
    <Page location={location}>
      <main>
        <Helmet>
          {/* Use the default description */}
          {/* <meta name="description" content=""/> */}
        </Helmet>
        <Section>
          <LayoutRow>
            <MainColumn>
              <h1>Message received</h1>
              <p>Thanks for reaching out, we'll be in touch!</p>
            </MainColumn>
          </LayoutRow>
        </Section>
      </main>
    </Page>
  );
};

export default ContactSubmitPage;
